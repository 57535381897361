import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';


// 获取管理类问题统计信息
export function getManageStatistic(data){
    return request('/problem/getManageStatistic.do',"POST",data)
}

// 获取制件类问题统计信息
export function getPartStatistic(data){
    return request('/problem/getPartStatistic.do',"POST",data)
}

