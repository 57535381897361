import request from "@/utils/request"
import SystemUtils from '@/utils/SystemUtils';
// 管理类 获取问题列表
export function getProblemListByManage(data){
    return request('/problem/getProblemListByManage.do',"POST",data)
}

// 制件类 获取问题列表
export function getProblemListByPart(data){
    return request('/problem/getProblemListByPart.do',"POST",data)
}
