<template>
  <ion-page>
    <Header type="0"
            :title="type==0?'管理类问题记录列表':'制件类问题记录列表'"
            bgColor="white"
            :btnIconRight="btnIconRight"
            :btnSizeRight="btnSizeRight"
            v-if="menuRole=='phone'"
            @transfer="searchQuestion"> </Header>
    <ion-content id="taskMain" :scroll-y="true">
      <div class="allTarget" slot="fixed">
        <div class="categoryBox">
          <keep-alive v-if="isRouterAlive">
            <question-state-manage
                  class="categoryItem"
                  :class="{cateSelected:dimensionality.isChecked}"
                  v-for="(dimensionality, index) in dimensionalityArr"
                  :key="index"
                  :question="dimensionality"
                  :screenConditions="screenConditions"
                  @click="changeCate(index)">
              {{ dimensionality.text }}
            </question-state-manage>
          </keep-alive>
        </div>
      </div>
      <div class="dataList" id="taskPageList">
<!--        <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>-->
        <div class="taskCardList" v-if="taskList.length > 0">
          <question-card
            v-for="(item, index) in taskList"
            :key="index"
            :question="item"
            @nav="openTaskInfo(item)"
            style="margin-top: 10px"
          ></question-card>
        </div>
        <!-- <ion-infinite-scroll threshold="50px" @ionInfinite="nextPage($event)">
          <ion-infinite-scroll-content
            loadingSpinner="bubbles"
          ></ion-infinite-scroll-content>
        </ion-infinite-scroll> -->
        <!-- 无数据  -->
        <div class="no_data" v-if="taskList.length <= 0 && !islist">
          <img
            class="no_data_img no_data_top"
            src="@/assets/images/notDate/notSearch.png"
            alt
          />
          <p class="no_data_text">未查询到信息</p>
        </div>
        <CardLoading
          v-if="taskList.length <= 0 && islist"
          :isHome="false"
          lodingType="1"
        ></CardLoading>
      </div>
      <!-- <ion-fab
        horizontal="end"
        vertical="bottom"
        slot="fixed"
        @click="addTaskHandler()"
      >
        <ion-fab-button>
          <span class="iconfont icon-naotujia"></span>
        </ion-fab-button>
      </ion-fab> -->
    </ion-content>
    <!-- <ion-footer class="ion-no-border">
      <div class="selectOption">
        <div>
          <ion-checkbox color="light" @ionChange="change"></ion-checkbox>
          <ion-text class="questionTitle">全选</ion-text>
        </div>
        <div>当前已选问题数：<span>3</span></div>
        <ion-button>发送</ion-button>
      </div>
    </ion-footer> -->
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonContent,
  menuController,
  popoverController,
  modalController,
} from "@ionic/vue";
import CardLoading from "@/components/ListCard/CardLoading.vue";
import questionCard from "./questionCard.vue";
import Header from "@/components/Header.vue";
import {getProblemListByManage,getProblemListByPart} from "@/api/questionManage/questionManage"
import * as dd from "dingtalk-jsapi";
import QuestionSearchManage from "@/views/questionsManage/QuestionSearchManage.vue";
import questionStateManage from "./questionStateManage.vue";

export default {
  name: "question",
  components: {
    IonPage,
    IonContent,
    menuController,
    popoverController,
    modalController,
    Header,
    questionCard,
    questionStateManage,
    CardLoading,
  },
  data() {
    return {
      btnIconRight: ["icon-sousuo"],
      btnSizeRight: ["34px"],
      targetIndex: 0,
      queryParam:{

      },
      type: 0,//0 管理类  1 制件类
      dimensionalityArr: [
        { value: "0", text: "临时解决",isChecked:true,name:'isTempMeasures', },
        { value: "1", text: "正式解决",isChecked:false,name:'isFormalMeasures' },
      ],
      taskOrder: {},
      showNoDate: false, //展示暂无数据
      islist: false, //是否显示加载中的标识
      taskList: [

      ],
      selSeg: 0,
      menuRole:'phone',
      isRouterAlive: true,
      screenConditions: null,
    };
  },
   created(){
     if(localStorage.getItem('isDetail')){
       const screenConditions = localStorage.getItem('screenConditions');
       if (screenConditions != null) {
         this.screenConditions = JSON.parse(screenConditions);
         localStorage.removeItem('screenConditions');
       }
       const queryParam = localStorage.getItem('queryParam');
       if (queryParam != null) {
         this.queryParam = JSON.parse(queryParam);
         this.dimensionalityArr.forEach((item, index) => {
           item.isChecked = this.queryParam[item.name];
         });
         localStorage.removeItem('queryParam');
       }
       localStorage.removeItem('isDetail');
     }else{
       this.queryParam = {
         isLoading:this.targetIndex==0?1:0,
         isEnd:this.targetIndex==0?1:0
       }
     }
     this.type = this.$route.params.type
      this.menuRole = localStorage.getItem("menuRole");
     if (this.menuRole == "dd") {
      dd.ready(function () {
        dd.biz.navigation.setRight({
          show: false, //控制按钮显示， true 显示， false 隐藏， 默认true
          control: true, //是否控制点击事件，true 控制，false 不控制， 默认false
          text: "发送", //控制显示文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            //如果control为true，则onSuccess将在发生按钮点击事件被回调
            /*
        {}
        */
          },
          onFail: function (err) {},
        });
        dd.biz.navigation.setTitle({
          title: '问题记录列表', //控制标题文本，空字符串表示显示默认文本
          onSuccess: function (result) {
            /*结构
        {
        }*/
          },
          onFail: function (err) {},
        });
      });
    }
  },
  mounted(){
    this.getList()
  },
  methods: {
    getList(){
      this.dimensionalityArr.forEach(e=>{
        this.queryParam[e.name] = e.isChecked?1:0
      })
      if(this.screenConditions){ //筛选条件
        this.queryParam.stageCode = this.screenConditions.stageCode;
        this.queryParam.isLoading = this.screenConditions.isLoading;
        this.queryParam.isEnd = this.screenConditions.isEnd;
      }
      let func;
      func = this.type==0?getProblemListByManage:getProblemListByPart
      func(this.queryParam).then(res=>{
        this.taskList = res.data.data.resultData
      })
    },
    change() {},
    changeCate(idx){
      this.dimensionalityArr[idx].isChecked=!this.dimensionalityArr[idx].isChecked
       this.getList()
    },
    openTaskInfo(event){
      localStorage.setItem('isDetail', true); //筛选条件
      localStorage.setItem('screenConditions', JSON.stringify(this.screenConditions)); //筛选条件
      localStorage.setItem('queryParam', JSON.stringify(this.queryParam)); //所有条件;;
      this.$router.push({
        path:'/questionView',
        query:{
          id:event.id
        }
      })
    },
    async searchQuestion() {
      const modal = await modalController.create({
        component: QuestionSearchManage,
        cssClass: "project-status-modal-class",
        componentProps: {
          dimensionalityArr: this.dimensionalityArr,
          screenConditions: this.screenConditions,
        },
      });
      await modal.present();
      const { data } = await modal.onDidDismiss();
      if (data) {
        this.screenConditions = data.screenConditions;
        this.taskList = data.taskList
        this.reload();
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      })
    },
  },
};
</script>
<style lang="scss" scoped>
#taskMain {
  .searchBox {
    width: 100%;
    height: px2rem(80);
    line-height: px2rem(80);
    padding: 0 px2rem(10);
    display: flex;
    justify-content: space-around;
    color: #3b3b47;
    font-size: $navigation-font-size;
    background: $default-background;
    .searchItem {
      width: 26%;
      display: flex;
      justify-content: center;
      padding: 0 px2rem(8);
      position: relative;
    }
    .searchItem1 {
      width: 22%;
    }
    .order-select {
      color: $font-segment-title-color;
    }
    p {
      font-size: 14px;
    }
    .ver-line {
      width: px2rem(2);
      display: flex;
      div {
        width: px2rem(2);
        background-color: #c0c0c0;
        height: px2rem(28);
        margin: auto;
      }
    }
    .targetIndex {
      color: $font-segment-title-color;
    }
    .searchLine {
      height: px2rem(30);
      border-right: 0.01rem solid #e4ebf0;
      margin: px2rem(25) 0;
    }
    .searchSelect {
      position: absolute;
      // left: calc(50% - #{px2rem(30)});
      bottom: 0.7rem;
      width: px2rem(60);
      height: px2rem(2);
      background: $font-segment-title-color;
    }
    .iconfont {
      margin-left: px2rem(10);
      font-size: $so-very-little-font-size;
      width: px2rem(25);
    }
  }
  .allTarget {
    width: 100%;
    background: $page-background;
  }
  .segment {
    background: $page-background;
    height: 4.5rem;
    .segment_button {
      --padding-start: 1.3rem;
      --padding-end: 1.3rem;
      --padding-top: 0;
      --padding-bottom: 0;
      margin: 0 px2rem(8);
      min-height: px2rem(54);
    }

    .segment-button-checked {
      background: #ffffff;
      color: $color;
    }
  }
  .categoryBox {
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
    margin: px2rem(20) px2rem(20) px2rem(10);
    .category {
      padding: px2rem(11) px2rem(22);
      font-size: $default-font-size;
      border-radius: 2rem;
      background: #fff;
    }
    .categorySelect {
      background: #f38467;
      color: #fff;
    }
    .categoryItem {
      padding: 0 15px;
      background: white;
      line-height: 3.375rem;
      text-align: center;
      color: #6d6c6c;
      font-size: $default-font-size;
    }
    .cateSelected{
      color: #0e67ed;
    }
  }
  ion-refresher {
    margin-top: px2rem(100);
  }
  .dataList {
    margin-top: px2rem(90);
  }
}
.title {
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: bold;
  color: $color;
}
.selectOption {
  width: 100%;
  padding: 10px 12px 10px 27px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    ion-checkbox {
      margin-right: 11px;
    }
    .questionTitle {
      font-size: 13px;
      font-family: Tensentype ChaoHeiJ, Tensentype ChaoHeiJ-Bold;
      font-weight: 700;
      color: #333333;
    }
  }
  > div:nth-of-type(1) {
    display: flex;
    justify-content: start;
    align-items: center;
  }
  > div:nth-of-type(2) {
    font-size: 11px;
    font-family: Microsoft YaHei, Microsoft YaHei-Regular;
    font-weight: bold;
    text-align: left;
    color: #333333;
    span {
      color: #ed910e;
    }
  }
  ion-button {
    --background: #ed910e;
    --padding-start: 58px;
    --padding-end: 58px;
    --background-activated: gray;
  }
}
ion-fab-button {
  --background: #0e67ed;
  // #003ca7
}
</style>